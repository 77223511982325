import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierComponent } from '../controls/notifier/notifier.component';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {

  constructor(private snackBar: MatSnackBar) { }

  showNotificationError(message: string) {
    
    this.snackBar.openFromComponent(NotifierComponent, {
      data: {
        message: message,
        snackType: 'Error'
       },
      duration: 0,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error-snackbar'
    });
    /*
        this.snackBar.open(message, buttonText, {
          duration: 0,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar'
        });

        */
  }

  showNotificationSuccess(message: string) {
    
    this.snackBar.openFromComponent(NotifierComponent, {
      data: {
        message: message,
        snackType: 'Success'
       },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-snackbar'
    });
    /*
        this.snackBar.open(message, buttonText, {
          duration: 0,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar'
        });

        */
  }

}
