import { DataEntry } from "../../shared/models/data-entry";
import { UiAction } from "../models/ui-action.models";

export const DYNAMIC_FEATURE_KEY = 'dynamic';

export interface DynamicState {
    local: DataEntry[];
    session: DataEntry[];
    actions: UiAction[];
}

export const initialDynamicState: DynamicState = {
    local: [],
    session: [],
    actions: [],
};