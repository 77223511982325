import { UiAction } from "src/app/dynamic-ui/models/ui-action.models";
import { DataEntry } from "../models/data-entry";
import { UiDefinition } from "src/app/dynamic-ui/models/ui-definition.models";
import { UiComponent } from "src/app/dynamic-ui/models/ui-component.models";

export function AddOrUpdateData(dataStore: DataEntry[], storeDataName: string, storeDataPath: string, newValue: any): DataEntry[] {
    if (dataStore.find(c => c.name === storeDataName)) {
        // Update
        return UpdateData(dataStore, storeDataName, storeDataPath, newValue);
    }
    else {
        // Add
        if (storeDataPath === '.' || storeDataPath === '') {
            return [...dataStore, { name: storeDataName, value: newValue }];
        }
        else {
            if (storeDataPath.startsWith('.')) storeDataPath = storeDataPath.slice(1);
            return [...dataStore, { name: storeDataName, value: { [storeDataPath]: newValue } }];
        }
    }
}

export function UpdateData(dataStore: DataEntry[], storeDataName: string, storeDataPath: string, newValue: any): DataEntry[] {
    return dataStore.map(data => {
        if (data.name.toLowerCase() !== storeDataName.toLowerCase())
            return { ...data };

        if (storeDataPath === '.' || storeDataPath === '') {
            return {
                ...data,
                value: newValue
            }
        }
        else {
            if (storeDataPath.startsWith('.')) storeDataPath = storeDataPath.slice(1);
            return {
                ...data,
                value: {
                    ...data.value,
                    [storeDataPath]: newValue
                }
            }
        }

    })
}

export function RemoveData(dataStore: DataEntry[], storeDataName: string, storeDataPath: string): DataEntry[] {
    return AddOrUpdateData(dataStore, storeDataName, storeDataPath, null);
}

export function GetActionsFromUiDefinition(uiDefinition: UiDefinition): UiAction[] {
    const flatElements = (array: UiComponent[]) => {
        // Recursive function
        const traverse = (subArray: UiComponent[], acc: UiAction[] = []) => {
            subArray.forEach(element => {
                // If object with certain condition push it in the resulting acc 
                if (element.type.toLowerCase() === 'action') {
                    acc.push(element as UiAction);
                }
                // If subordinates exist, traverse them deeply
                if (element.children) {
                    traverse(element.children, acc);
                }
            });
            // Return accumulator
            return acc;
        }
        return traverse(array, []);
    }

    return flatElements(uiDefinition.definition);
}
