<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{title$ | async}}
          </mat-panel-title>
          <mat-panel-description>
            {{description$ | async}}
          </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container *ngFor="let child of children">
        <ng-template #children></ng-template>
    </ng-container>
</mat-expansion-panel>