import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './auth.actions';
import { AuthState, TokenStatus, initialState } from './auth.state';

const reducer = createReducer(
  initialState,

  // Login
  on(
    fromActions.login,
    (state): AuthState => ({
      ...state,
      accessTokenStatus: TokenStatus.VALIDATING,
      isLoadingLogin: true,
      hasLoginError: false,
    })
  ),

  // Refresh token
  on(
    fromActions.refreshToken,
    (state): AuthState => ({
      ...state,
      refreshTokenStatus: TokenStatus.VALIDATING,
    })
  ),

  // Login & Refresh token
  on(
    fromActions.loginSuccess,
    fromActions.refreshTokenSuccess,
    (state): AuthState => ({
      ...state,
      isLoggedInChecked: true,
      isLoggedIn: true,
      isLoadingLogin: false,
      accessTokenStatus: TokenStatus.VALID,
      refreshTokenStatus: TokenStatus.VALID,
    })
  ),

  on(
    fromActions.loginFailure,
    fromActions.refreshTokenFailure,
    (state, action): AuthState => {
      return ({
        ...state,
        isLoggedInChecked: true,
        isLoadingLogin: false,
        accessTokenStatus: TokenStatus.INVALID,
        refreshTokenStatus: TokenStatus.INVALID
      });
    }
  ),

  // Logout
  on(
    fromActions.logout,
    (): AuthState => ({
      ...initialState,
      isLoggedInChecked: true
    })
  ),

  // Auth user
  on(
    fromActions.getAuthUserSuccess,
    (state, action): AuthState => ({
      ...state,
      isLoggedInChecked: true,
      isLoggedIn: (action.user?.mail ? true : false),
      user: action.user,
    })
  ),

  on(
    fromActions.getAuthUserFailure,
    (): AuthState => ({
      ...initialState,
      isLoggedInChecked: true
    })
  ),

  on(
    fromActions.selectClub, (state, action) => ({
      ...state,
      user: {
        ...state.user,
        selectedClub: action.selection
      }
    })
  ),

);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
