@switch (type?.toLowerCase()) {
@case("search") {
<mat-form-field class="width100procent rahmen">
    <mat-label>{{title$ | async}}</mat-label>
    <input matInput type="search" #inputElement [ngModel]="value$ | async" (ngModelChange)="onModelChange($event)" />
    <button matSuffix mat-icon-button aria-label="Search" (click)="clearValue()">
        <mat-icon>search</mat-icon>
    </button>
</mat-form-field>
}
@case("date") {
<mat-form-field class="width100procent rahmen">
    <mat-label>{{title$ | async}}</mat-label>
    <input matInput readonly [matDatepicker]="picker" type='datetime' #inputElement [ngModel]="value$ | async"
        (ngModelChange)="onModelChangeDate($event)" />
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
}
@case("password") {
<mat-form-field class="width100procent rahmen">
    <mat-label>{{title$ | async}}</mat-label>
    <input matInput [type]="passwordHide ? 'password' : 'text'" #inputElement [ngModel]="value$ | async"
        (ngModelChange)="onModelChange($event)" />
    <button mat-icon-button matSuffix (click)="passwordHide = !passwordHide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="passwordHide">
        <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
</mat-form-field>
}
@case("textarea") {
<mat-form-field class="width100procent rahmen">
    <mat-label>{{title$ | async}}</mat-label>
    <textarea matInput #inputElement [ngModel]="value$ | async | objectToJson" (ngModelChange)="onModelChange($event)"
        [readonly]="readonly" [rows]="textAreaRows"></textarea>
</mat-form-field>
}
@default {
<mat-form-field class="width100procent rahmen">
    <mat-label>{{title$ | async}}</mat-label>
    <input matInput #inputElement [ngModel]="value$ | async" (ngModelChange)="onModelChange($event)"
        [readonly]="readonly" />
    <button *ngIf="inputElement.value && withClearButton" matSuffix mat-icon-button aria-label="Clear"
        (click)="clearValue()">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field> }
}
