<mat-form-field *ngIf="withFilter" class="width100procent rahmen filtertextbox">
    <mat-label>Filter</mat-label>
    <mat-icon matPrefix>filter_alt</mat-icon>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filterwert" #filterElement>
    <button *ngIf="filterElement.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="clearFilter(filterElement)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

<div class="mat-elevation-z2">
    <mat-table *ngIf="dataSource" #table [dataSource]="dataSource" [style.max-height]="maxHeight" [style.min-height]="maxHeight"
        [style.overflow]="(maxHeight) ? 'auto' : ''" matSort>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data-cell" [attr.colspan]="displayedColumns.length">Keine Daten verfügbar.</td>
        </tr>

        @if (withSelection) {
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggleRow(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        }

        @if (columns) {
        @for (column of columns; track column) {
        <!-- Key Column -->
        <ng-container [matColumnDef]="column.dataStoreKey">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{column.headerTitle}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{element[column.dataStoreKey]}}</mat-cell>
        </ng-container>
        }
        }

        @if (withRemove) {
        <ng-container matColumnDef="remove">
            <mat-header-cell *matHeaderCellDef>
                <mat-icon (click)="removeAll(); $event.stopPropagation();" fontIcon="delete_forever" color="primary">
                </mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-icon (click)="removeRow(row); $event.stopPropagation();" fontIcon="delete" color="primary">
                </mat-icon>
            </mat-cell>
        </ng-container>
        }

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'selected' : selection.isSelected(row) }"
            (click)="toggleRow(row)"></mat-row>

    </mat-table>

    <mat-paginator *ngIf="withPagination" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
        aria-label="Select page of periodic elements">
    </mat-paginator>
</div>
