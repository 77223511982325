<div class="mat-elevation-z2">
    <!-- <mat-table *ngIf="dataSource" #table [dataSource]="dataSource" [style.max-height]="maxHeight"
        [style.overflow]="(maxHeight) ? 'auto' : ''" matSort>
    </mat-table> -->

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" [style.max-height]="maxHeight" [style.min-height]="maxHeight"
        [style.overflow]="(maxHeight) ? 'auto' : ''">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" (click)="activeNode = node;selectItem(node.value)" [ngClass]="{ 'selected': activeNode === node }" matTreeNodePadding matTreeNodePaddingIndent="16">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled class="small-icon-button"></button>
            <mat-icon class="icon-display">
                {{getIcon(node)}}
            </mat-icon>
            {{node.name}}
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" (click)="activeNode = node;selectItem(node.value)" [ngClass]="{ 'selected': activeNode === node }" matTreeNodePadding matTreeNodePaddingIndent="16">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
                class="small-icon-button">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <mat-icon class="icon-display">
                {{getIcon(node)}}
            </mat-icon>
            {{node.name}}
        </mat-tree-node>
    </mat-tree>

</div>
