import { createAction, props } from '@ngrx/store';
import { UiDefinition } from '../models/ui-definition.models';
import { UiComponent } from '../models/ui-component.models';
import { UiAction } from '../models/ui-action.models';

export const addActionToStore = createAction(
    '[Dynamic] add action to store',
    props<{ uiAction: UiAction }>()
);

export const executeAction = createAction(
    '[Dynamic] execute action',
    props<{ actionName: string }>()
);

export const executeActionSuccess = createAction(
    '[Dynamic] execute action success',
    props<{ uiAction: UiAction }>()
);

export const executeActionFailed = createAction(
    '[Dynamic] execute action failed',
    props<{ actionName: string }>()
);

export const initLocalStore = createAction(
    '[Dynamic] init local store',
    props<{ uiDefinition: UiDefinition }>()
);

export const executeStoredProcedure = createAction(
    '[Dynamic] execute Stored Procedure',
    props<{ actionName: string, uiAction: UiAction }>()
);

export const executeStoredProcedureSuccess = createAction(
    '[Dynamic] execute Stored Procedure success',
    props<{ actionName: string, json: any | any[], uiAction: UiAction }>()
);

export const executeStoredProcedureFailed = createAction(
    '[Dynamic] execute Stored Procedure failed',
    props<{ actionName: string }>()
);

export const generatePDF = createAction(
    '[Dynamic] generate PDF',
    props<{ actionName: string, uiAction: UiAction }>()
);

export const generatePDFSuccess = createAction(
    '[Dynamic] generate PDF success',
    props<{ actionName: string, json: any | any[] }>()
);

export const generatePDFFailed = createAction(
    '[Dynamic] generate PDF failed',
    props<{ actionName: string }>()
);

export const sendMail = createAction(
    '[Dynamic] send Mail',
    props<{ actionName: string, uiAction: UiAction }>()
);

export const sendMailSuccess = createAction(
    '[Dynamic] send Mail success',
    props<{ actionName: string, json: any | any[] }>()
);

export const sendMailFailed = createAction(
    '[Dynamic] send Mail failed',
    props<{ actionName: string }>()
);

export const saveDataInStore = createAction(
    '[Dynamic] save data in store',
    props<{ storeName: string, storeDataName: string, storeDataPath: string, newValue: string }>()
);

export const removeDataInStore = createAction(
    '[Dynamic] remove data in store',
    props<{ storeName: string, storeDataName: string, storeDataPath: string }>()
);
