import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthFacade } from '../store/auth.facade';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NotifierService } from 'src/app/app/services/notifier.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authFacade: AuthFacade,
    private authService: AuthService,
    private notifierService: NotifierService,
    private route: Router
  ) { 
    
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({ headers: req.headers.append('Session-Guid', this.authService.getSessionGuid() ?? '') });
    if (req.url.indexOf('/auth/refresh_token') > -1 || req.url.indexOf('/auth/login') > -1) {
      return next.handle(clonedRequest);
    }
    return next.handle(clonedRequest).pipe(
      catchError((error) => {
        // Check if unauthorized
        if (error.status == 401) {
          return this.handle401Error(clonedRequest, next, error);
        } else {
          this.notifierService.showNotificationError(error.error?.errorMessage ?? error.message);
          return throwError(() => error);
        }
      })
    );
  }

  // Handle Unauthorized Error
  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler,
    originalError: any
  ) {
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        return next.handle(req);
      }),
      catchError((error) => {
        this.notifierService.showNotificationError(error.error?.errorMessage ?? error.message);
        //this.authFacade.logout();
        this.route.navigate(['/']);
        return throwError(() => originalError);
      })
    );
  }
}
