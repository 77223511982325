import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn, ActivatedRoute, mapToCanActivate
} from '@angular/router';
import {catchError, of} from 'rxjs';
import { map } from 'rxjs/operators';
import { selectIsLoggedIn } from '../store/auth.selectors';
import { Store } from '@ngrx/store';

export const NoAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);

  return store.select(selectIsLoggedIn).pipe(
    map(loggedIn => loggedIn ? false : true),
    catchError((err) => {
      router.navigate(['/']);
      return of(false);
    })
  )
}