import { AfterViewInit, Component, ComponentRef, Injector, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent extends DynamicBaseClass implements OnInit, OnDestroy, AfterViewInit {

  private componentRef: ComponentRef<DynamicBaseClass>;
  @ViewChildren('children', { read: ViewContainerRef }) viewChildrenRef: QueryList<ViewContainerRef>;

  public title$: Observable<string>;
  public subtitle$: Observable<string>;
  onSaveClickExecuteActionName: string;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.title$ = this.GetTitleFromConfig();
    this.subtitle$ = this.GetSubtitleFromConfig();
    if (this.config) {
      this.onSaveClickExecuteActionName = this.config.onSaveExecuteActionName
      super.GlobalConfig();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (this.children) {
      setTimeout(() => {
        this.AddComponentsFromChildren(this.viewChildrenRef, this.componentRef);
      });
    }
  }

  onSaveClick() {
    this.dynamicFacade.ExecuteAction(this.onSaveClickExecuteActionName);
  }
}

