import { HeaderMenu } from "../models/header-menu.models";
import { VereinSmall } from "../models/verein-small.models";

export const APP_FEATURE_KEY = 'app';

export interface AppState {
    version: string,
    title: string,
    availableClubs: VereinSmall[],
    selectedClub: VereinSmall,
    headerMenu: HeaderMenu[]
  }
  
  export const initialAppState: AppState = {
    version: "",
    title: "Willkommen",
    availableClubs: [],
    selectedClub: null,
    headerMenu: []
  };