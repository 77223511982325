<button mat-icon-button [matMenuTriggerFor]="headerMenuUser" class="menu-icon" aria-label="Header menu user">
  <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #headerMenuUser="matMenu">
  <ng-container *ngIf="!(isLoggedIn$ | async)">
    <button mat-menu-item routerLink="/login">
      Anmelden
    </button>
    <button mat-menu-item routerLink="/signup">
      Registrieren
    </button>

  </ng-container>
  <ng-container *ngIf="isLoggedIn$ | async">
    <p class="center">
      Willkommen<br />
      {{(loggedInUser$ | async)?.firstName}} {{(loggedInUser$ | async)?.lastName}}
      <br />
      <span class="mat-small">{{(loggedInUser$ | async)?.mail}}</span>
    </p>
    <mat-divider></mat-divider>
    <a mat-menu-item routerLink="/login" routerLink="active-link">
      Benutzer wechseln
    </a>
    <a mat-menu-item routerLink="/signup">
      Neuen Benutzer registrieren
    </a>
    <a mat-menu-item (click)="onLogout()">
      <span>Abmelden</span>
    </a>
  </ng-container>

</mat-menu>
