import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './app/views/home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AuthGuard } from './auth/guards';
import { DynamicComponent } from './dynamic-ui/views/dynamic/dynamic.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', title: "Easy Anlass", data: { title: "Willkommen" } },
  { path: 'club', loadChildren: () => import("./club/club.module").then(m => m.ClubModule), title: "Easy Anlass", canActivate: [AuthGuard], data: { title: "Vereinsverwaltung" } },
  { path: 'counter', component: CounterComponent, title: "Easy Anlass", data: { title: "Counter" } },
  { path: 'fetch-data', component: FetchDataComponent, title: "Easy Anlass", data: { title: "Fetch Data" } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
