<ng-container *ngIf="(this.headerMenus$ | async).length > 0">
  <button mat-icon-button [matMenuTriggerFor]="headerMenuConfig" class="menu-icon"
    aria-label="Header menu config">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #headerMenuConfig="matMenu">
    <ng-container *ngFor="let menu of (this.headerMenus$ | async)">
      <a *ngIf="menu.url != '-'" mat-menu-item [routerLink]="menu.url" routerLinkActive="link-active"
        [routerLinkActiveOptions]="{ exact: true }">
        <span>{{menu.displayName}}</span>
      </a>
      <mat-divider *ngIf="menu.url === '-'"></mat-divider>
    </ng-container>
  </mat-menu>
</ng-container>