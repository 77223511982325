import { createAction, props } from '@ngrx/store';

import { AuthUser, ResponseError } from './auth.state';
import { SignupRequest } from '../models/signup-request.models';
import { VereinSmall } from 'src/app/app/models/verein-small.models';
import { LoginResponse } from '../models/login-response.models';

// Login
export const login = createAction(
  '[Auth] Login',
  props<{ username: string; password: string; rememberThisUser: boolean }>()
);
export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ response: LoginResponse}>()
);
export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: ResponseError }>()
);

// Logout
export const logout = createAction('[Auth] Logout');

// SignUp
export const signup = createAction(
  '[Auth] SignUp',
  props<{ signUpRequest: SignupRequest }>()
);
export const signUpSuccess = createAction('[Auth] SignUp Success');
export const signUpFailure = createAction(
  '[Auth] SignUp Failure',
  props<{ error: ResponseError }>()
);

// Auth User: me
export const getAuthUserRequest = createAction('[Auth] Auth User');
export const getAuthUserSuccess = createAction(
  '[Auth] Auth User Success',
  props<{ user: AuthUser }>()
);
export const getAuthUserFailure = createAction('[Auth] Auth User Failure');

// Refresh token
export const refreshToken = createAction('[Auth] Refresh Token');
export const refreshTokenSuccess = createAction('[Auth] Refresh Token Success');
export const refreshTokenFailure = createAction(
  '[Auth] Refresh Token Failure',
  props<{ error: ResponseError }>()
);

// Clubs
export const selectClub = createAction(
  '[Auth] select club',
  props<{ selection: VereinSmall }>()
);
