import {
    AfterViewInit,
    Component,
    ComponentRef,
    Injector,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';

@Component({
    selector: 'app-dynamic-grid-item',
    templateUrl: './grid-item.component.html',
    styleUrls: ['./grid-item.component.scss'],
})
export class GridItemComponent
    extends DynamicBaseClass
    implements OnInit, OnDestroy, AfterViewInit {
    private componentRef: ComponentRef<DynamicBaseClass>;
    @ViewChildren('children', { read: ViewContainerRef })
    viewChildrenRef: QueryList<ViewContainerRef>;

    protected gridRow: string = undefined;
    protected gridColumn: string = undefined;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.config) {
            if (this.config.gridRow) {
                this.gridRow = this.config.gridRow;
            }
            if (this.config.gridColumn) {
                this.gridColumn = this.config.gridColumn;
            }
        }
        super.GlobalConfig();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        if (this.children) {
            setTimeout(() => {
                this.AddComponentsFromChildren(
                    this.viewChildrenRef,
                    this.componentRef
                );
            });
        }
    }
}
