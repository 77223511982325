import { HttpClient, HttpParams } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, OnInit, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, Observable, throwError } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import * as AuthActions from '../store/auth.actions';
import { AuthState, AuthUser, TokenStatus } from '../store/auth.state';
import * as AuthSelectors from '../store/auth.selectors';
import { sanitizeIdentifier } from '@angular/compiler';
import { SignupResponse } from '../models/signup-response.models';
import { SignupRequest } from '../models/signup-request.models';
import { LoginResponse } from '../models/login-response.models';

export interface AccessData {
  token_type: 'Bearer';
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

@Injectable()
export class AuthService {

  readonly NameSessionGuid = 'SessionGuid';

  constructor(
    private http: HttpClient,
  ) {
  }

  setSessionGuid(sessionGuid: string) {
    sessionStorage.setItem(this.NameSessionGuid, sessionGuid);
  }

  getSessionGuid(): string {
    return sessionStorage.getItem(this.NameSessionGuid);
  }

  /**
   * Returns a promise that waits until
   * refresh token and get auth user
   *
   * @returns {Promise<AuthState>}
   */
  // init(): Promise<AuthState> {
  //   this.store.dispatch(AuthActions.refreshToken());

  //   const authState$ = this.store.select(AuthSelectors.authQuery).pipe(
  //     filter(
  //       auth =>
  //         auth.refreshTokenStatus === TokenStatus.INVALID ||
  //         (auth.refreshTokenStatus === TokenStatus.VALID && !!auth.user)
  //     ),
  //     take(1)
  //   );

  //   return lastValueFrom(authState$);
  // }

  /**
   * Performs a request with user credentials
   * in order to get auth tokens
   *
   * @param {string} username
   * @param {string} password
   * @returns Observable<AccessData>
   */
  login(username: string, password: string, rememberThisUser: boolean): Observable<LoginResponse> {
    return this.http.post<LoginResponse>("/api/auth/login", {
      'username': username,
      'password': password,
      'rememberThisUser': rememberThisUser
    });
  }

  /**
   * Performs a request for logout authenticated user
   *
   * @param {('all' | 'allButCurrent' | 'current')} [clients='current']
   * @returns Observable<void>
   */
  logout(): Observable<void> {
    return this.http.get<void>("/api/auth/logout");
  }

  /**
   * Asks for a new access token given
   * the stored refresh token
   *
   * @returns {Observable<AccessData>}
   */
  refreshToken(): Observable<void> {
    return this.http.get<void>("/api/auth/refresh_token");
  }

  /**
   * Returns authenticated user
   * based on saved access token
   *
   * @returns {Observable<AuthUser>}
   */
  getAuthUser(): Observable<AuthUser> {
    return this.http.get<AuthUser>("/api/auth/userinformation");
  }

  signUp(signUpRequest: SignupRequest): Observable<SignupResponse> {
    return this.http.post<SignupResponse>("/api/auth/signup", signUpRequest);
  }

}

// export const authServiceInitProvider: Provider = {
//   provide: APP_INITIALIZER,
//   useFactory: (authService: AuthService) => () => authService.init(),
//   deps: [AuthService],
//   multi: true,
// };
