@if (withSelection){
<mat-form-field class="width100procent">
    <mat-label>{{(title$ | async)}}</mat-label>
    <mat-select #select (selectionChange)="onModelChangeMultiple($event)" multiple>
        <mat-select-trigger>
            {{ selectedValues && toString.call(selectedValues) === "[object Array]" && selectedValues.length > 0 ? selectedValues[0] || '' : ''}}
            @if (selectedValues && toString.call(selectedValues) === "[object Array]" && ((selectedValues.length || 0) > 1)) {
            <span class="example-additional-selection">
                (+{{(selectedValues.length || 0) - 1}} {{selectedValues.length === 2 ? 'weiterer' : 'weitere'}})
            </span>
            }
        </mat-select-trigger>
        @for (item of (dataSource$ | async); track $index) {
        <mat-option [value]="item.key">{{item.display}}</mat-option>
        }
    </mat-select>
</mat-form-field>
}
@else {
<mat-form-field class="width100procent">
    <mat-label>{{(title$ | async)}}</mat-label>
    <mat-select #select (ngModelChange)="onModelChange($event)" [compareWith]="compareSingle" [(ngModel)]="selectedValue">
        @if(withEmptyFirstLine){
        <mat-option [value]=""></mat-option>
        }
        @for (item of (dataSource$ | async); track $index) {

        <mat-option [value]="item.key">{{item.display}}</mat-option>

        }
    </mat-select>
</mat-form-field>
}
