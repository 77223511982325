<mat-card class="logon_card">
  <mat-card-content>
    <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
      <h2>Anmelden</h2>
      <mat-error *ngIf="!loginValid">
        The username and password were not recognized
      </mat-error>
      <mat-form-field class="fill_width">
        <mat-label>Email oder SSV-Lizenznummer</mat-label>
        <input matInput [(ngModel)]="username" name="username" required>
        <mat-error>
          Email-Adresse oder SSV-Lizenznummer
        </mat-error>
      </mat-form-field>
      <mat-form-field class="fill_width">
        <mat-label>Passwort</mat-label>
        <input matInput type="password" [(ngModel)]="password" name="password" required>
        <mat-error>
          Bitte gib ein gültiges Passwort an
        </mat-error>
      </mat-form-field>
      <mat-checkbox (change)="onRememberThisUserChange($event)" [checked]="rememberThisUser">
        Mit diesem Benutzer angemeldet bleiben
      </mat-checkbox>
      <br />
      <br />
      <button mat-raised-button color="primary" [disabled]="!loginForm.form.valid">Anmelden</button>
    </form>
  </mat-card-content>
</mat-card>