import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-label-with-title',
  templateUrl: './label-with-title.component.html',
  styleUrls: ['./label-with-title.component.scss']
})
export class LabelWithTitleComponent extends DynamicBaseClass implements OnInit, OnDestroy {

  public value$: Observable<string>;
  public title$: Observable<string>;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.value$ = this.GetValueFromConfig();
    this.title$ = this.GetTitleFromConfig();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

