<div [hidden]="!viewCondition">
    <mat-tab-group>
        <ng-container *ngFor="let child of children; index as i">
            <mat-tab [label]="label[i] ?? ''">
                <div class="tabcontent">
                    <ng-template #children></ng-template>
                </div>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</div>
