import {
    AfterViewInit,
    Component,
    ComponentRef,
    Injector,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { DynamicUiService } from '../../services/dynamic-ui.service';

@Component({
    selector: 'app-dynamic-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
})
export class GridComponent
    extends DynamicBaseClass
    implements OnInit, OnDestroy, AfterViewInit {
    private componentRef: ComponentRef<DynamicBaseClass>;
    @ViewChildren('children', { read: ViewContainerRef })
    viewChildrenRef: QueryList<ViewContainerRef>;

    protected gridTemplateRows: string = undefined;
    protected gridTemplateColumns: string = undefined;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.config) {
            if (this.config.gridTemplateRows) {
                this.gridTemplateRows = this.config.gridTemplateRows;
            }
            if (this.config.gridTemplateColumns) {
                this.gridTemplateColumns = this.config.gridTemplateColumns;
            }
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        if (this.children) {
            setTimeout(() => {
                this.AddComponentsFromChildren(
                    this.viewChildrenRef,
                    this.componentRef
                );
            });
        }
    }
}
