import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthState, AUTH_FEATURE_KEY } from './auth.state';

export const authQuery = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);
export const user = (state: AuthState) => state.user;
export const global = (state: AuthState) => state.user.global;

export const selectIsLoggedIn = createSelector(
    authQuery,
    state => state.isLoggedIn
);

export const selectIsLoggedInChecked = createSelector(
    authQuery,
    state => state.isLoggedInChecked
);

export const selectLoginError = createSelector(
    authQuery,
    state => state.hasLoginError
);

export const selectIsLoadingLogin = createSelector(
    authQuery,
    state => state.isLoadingLogin
);

export const getSelectedClub = createSelector(
    authQuery,
    state => state.user?.selectedClub
);

export const getSelectedClubName = createSelector(
    authQuery,
    state => state.user?.selectedClub?.vereinsnameKompl
);

export const getFirstClub = createSelector(
    authQuery,
    state => state.user?.firstClub
);

export const getAvailableClubs = createSelector(
    authQuery,
    state => state.user?.availableClubs
);

export const selectAuthUser = createSelector(
    authQuery,
    state => state.user
);

export const selectGlobal = createSelector(
    authQuery,
    state => state.user?.global ?? []
);

export const getHeaderMenus = createSelector(
    authQuery,
    state => state.user?.headerMenus ?? []
);

export const getUrls = createSelector(
    authQuery,
    state => state.user?.urls ?? []
);

export const getUiDefinitions = createSelector(
    authQuery,
    state => state.user?.uiDefinitions ?? []
);

export const getData = (name: string, srcpath: string) => createSelector(
    authQuery,
    (state: AuthState) => {
        var obj = state.user.global.find(c => (c.name as string).toLowerCase() === name.toLowerCase())?.value;
        if (obj) {
            if (srcpath !== '.' && srcpath !== '') {
                for (var i = 0, path = srcpath.split('.'), len = path.length; i < len; i++) {
                    obj = obj[path[i]];
                }
            }
            return obj;
        }
        else {
            return null;
        }
    }
);
