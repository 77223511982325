import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { VereinSmall } from '../../app/models/verein-small.models';
import { VereinMember } from 'src/app/club/models/verein-member.models';
import { VereinDetail } from 'src/app/club/models/verein-detail.models';
import { Vorstand } from '../models/verein-vorstand.models';

@Injectable({
    providedIn: 'root'
})
export class ClubService {
    private serviceApiUrl: string;

    constructor(private readonly http: HttpClient,
        @Inject('BASE_URL') baseUrl: string) {
        this.serviceApiUrl = `${baseUrl}api/verein`;
    }

    public getAvailableClubs(): Observable<VereinSmall[]> {
        const headers = { 'content-type': 'application/json' }
        return this.http.get<VereinSmall[]>(this.serviceApiUrl + "/meinevereine", { 'headers': headers });
    }

    public getVereinDetail(vereinSmall: VereinSmall): Observable<VereinDetail> {
        const headers = { 'content-type': 'application/json' }
        let queryParams = new HttpParams();
        queryParams = queryParams.append("id", vereinSmall.id);
        return this.http.get<VereinDetail>(this.serviceApiUrl + "/vereindetail", { 'params': queryParams, 'headers': headers });
    }

    public getVereinMembers(vereinSmall: VereinSmall): Observable<VereinMember[]> {
        const headers = { 'content-type': 'application/json' }
        let queryParams = new HttpParams();
        queryParams = queryParams.append("id", vereinSmall.id);
        return this.http.get<VereinMember[]>(this.serviceApiUrl + "/vereinmembers", { 'params': queryParams, 'headers': headers });
    }

    public getVorstand(vereinSmall: VereinSmall): Observable<Vorstand[]> {
        const headers = { 'content-type': 'application/json' }
        let queryParams = new HttpParams();
        queryParams = queryParams.append("id", vereinSmall.id);
        return this.http.get<Vorstand[]>(this.serviceApiUrl + "/vorstand", { 'params': queryParams, 'headers': headers });
    }


}