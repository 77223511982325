import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToJson'
})

export class ObjectToJsonPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (typeof value === 'object' && value !== null){
        return JSON.stringify(value, null, 2);
    }
    else
    {
        return value;
    }
  }
}
