import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState, APP_FEATURE_KEY } from './app.state';

export const appQuery = createFeatureSelector<AppState>(APP_FEATURE_KEY);

export const getVersion = createSelector(
    appQuery,
    (state: AppState) => state.version
);

export const getTitle = createSelector(
    appQuery,
    (state: AppState) => state.title
);
