import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './app.actions';
import { AppState, initialAppState } from './app.state';

const _appReducer = createReducer(
  initialAppState,

  on(fromActions.setTitle, (state, action) => ({
    ...state,
    title: action.title
  })),
  
)

export function appReducer(state = initialAppState, action: Action) {
  return _appReducer(state, action);
}