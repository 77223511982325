import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
    filter,
    first,
    map,
    mergeMap,
    switchMap,
    tap,
    catchError,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as fromActions from './dynamic.actions';
import { DynamicUiService } from '../services/dynamic-ui.service';
import { DynamicFacade } from './dynamic.facade';
import { NotifierService } from 'src/app/app/services/notifier.service';

@Injectable({
    providedIn: 'root',
})
export class DynamicEffects {
    constructor(
        private actions: Actions,
        private dynamicUiService: DynamicUiService,
        private notifierService: NotifierService,
        private dynamicFacade: DynamicFacade
    ) { }

    // Beim hinzufügen der Action zum Store wird geprüft, ob das Attribute executeOnInit auf true ist und führt die Action aus.
    // public addActionToStore: Observable<Action> = createEffect(() => this.actions.pipe(
    //     ofType(fromActions.addActionToStore),
    //     map(action => action),
    //     filter(action => action.uiAction.executeOnInit === true),
    //     map(action => fromActions.executeAction({ actionName: action.uiAction.name }))
    // ));

    // Beim hinzufügen der Action zum Store wird geprüft, ob das Attribute executeOnInit auf true ist und führt die Action aus.
    public initLocalStore: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromActions.initLocalStore),
                //last(),
                tap(() => {
                    this.dynamicFacade.actions$
                        .pipe(
                            filter((actions) => actions.length > 0),
                            map((uiActions) => {
                                return uiActions.filter(
                                    (item) => item.executeOnInit === true
                                );
                            }),
                            first(),
                            tap((uiActions) => {
                                console.log('Init UiActions ', uiActions);
                                return uiActions.map((uiAction) =>
                                    this.dynamicFacade.ExecuteAction(
                                        uiAction.name
                                    )
                                );
                            })
                        )
                        .subscribe();
                }),
                tap(() => {
                    this.dynamicFacade.actions$
                        .pipe(
                            filter((actions) => actions.length > 0),
                            map((uiActions) => {
                                return uiActions.filter(
                                    (item) =>
                                        item.executeOnDataStoresChanged &&
                                        item.executeOnDataStoresChanged.length >
                                        0
                                );
                            }),
                            first(),
                            tap((uiActions) => {
                                console.log(
                                    'Init UiActions on change Store ',
                                    uiActions
                                );
                                return uiActions.map((uiAction) => {
                                    uiAction.executeOnDataStoresChanged.forEach(
                                        (item) => {
                                            if (item) {
                                                this.dynamicUiService
                                                    .GetValueAsObservable(item)
                                                    .subscribe((itemValue) => {
                                                        if (itemValue) {
                                                            this.dynamicFacade.ExecuteAction(
                                                                uiAction.name
                                                            )
                                                        }
                                                    }
                                                    );
                                            }
                                        }
                                    );
                                });
                            })
                        )
                        .subscribe();
                })
            ),
        { dispatch: false }
    );

    // Führt eine Action
    public executeAction: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromActions.executeAction),
                tap((action) => {
                    this.dynamicFacade.GetAction(action.actionName).pipe(
                        first(),
                        tap((uiAction) => {
                            if (uiAction?.removeStoresBeforExecute != undefined && uiAction?.removeStoresBeforExecute.length > 0) {
                                uiAction.removeStoresBeforExecute.forEach(storeName => {
                                    this.dynamicUiService.RemoveValue(storeName);
                                });
                            }
                        })
                    ).subscribe();
                }
                ),
                mergeMap((action) =>
                    this.dynamicUiService
                        .ExecuteActionFromName(action.actionName)
                        .pipe(
                            map((uiAction) => {
                                console.log("execAction", uiAction);
                                this.dynamicFacade.ExecuteActionSuccess(uiAction);
                                return null;
                            })
                        )
                )
            ),
        { dispatch: false }
    );

    // Wird ausgeführt, wenn eine Action erfolgreich ausgeführt wurde
    public executeActionSuccess: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromActions.executeActionSuccess),
                tap((action) => {
                    if (action.uiAction?.executeAfter != undefined && action.uiAction?.executeAfter.length > 0 && action.uiAction.typeName.toLocaleLowerCase() != 'executestoredprocedure') {
                        action.uiAction.executeAfter.forEach(c => {
                            this.dynamicFacade.ExecuteAction(c);
                        });
                    }
                }
                )
            ),
        { dispatch: false }
    );

    public executeStoredProcedure: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(fromActions.executeStoredProcedure),
            mergeMap((loadData) =>
                this.dynamicUiService
                    .ActionExecuteStoredProcedure(loadData.uiAction)
                    .pipe(
                        map((result) => {
                            if (result.success && result.success == true) {
                                const json = JSON.parse(result?.result ?? '{}');
                                this.dynamicUiService.SetValue(
                                    loadData.uiAction.config.targetStoreData,
                                    json);
                                return fromActions.executeStoredProcedureSuccess(
                                    {
                                        actionName: loadData.actionName,
                                        json: json,
                                        uiAction: loadData.uiAction
                                    }
                                );
                            } else {
                                let errorMessge: string =
                                    result.errorCode +
                                    ': ' +
                                    result.errorMessage;
                                this.notifierService.showNotificationError(
                                    errorMessge
                                );
                                return fromActions.executeStoredProcedureFailed(
                                    {
                                        actionName: loadData.actionName
                                    }
                                );
                            }
                        }),
                        catchError((error) => {
                            this.notifierService.showNotificationError(error);
                            return of(
                                fromActions.executeStoredProcedureFailed({
                                    actionName: loadData.actionName
                                })
                            );
                        })
                    )
            )
        )
    );

    // Wird ausgeführt, wenn eine StoredProcedure erfolgreich ausgeführt wurde
    public executeStoredProcedureSuccess: Observable<Action> = createEffect(
        () =>
            this.actions.pipe(
                ofType(fromActions.executeStoredProcedureSuccess),
                tap((action) => {
                    if (action.uiAction?.executeAfter != undefined && action.uiAction?.executeAfter.length > 0) {
                        action.uiAction.executeAfter.forEach(c => {
                            this.dynamicFacade.ExecuteAction(c);
                        });
                    }
                }
                )
            ),
        { dispatch: false }
    );


    public generatePDF: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(fromActions.generatePDF),
            mergeMap((loadData) =>
                this.dynamicUiService
                    .ActionGeneratePDF(loadData.uiAction)
                    .pipe(
                        map((result) => {
                            if (result.success && result.success == true) {
                                const json = { pdf: result?.result };
                                this.dynamicUiService.SetValue(
                                    loadData.uiAction.config.targetStoreData,
                                    json);
                                return fromActions.generatePDFSuccess(
                                    {
                                        actionName: loadData.actionName,
                                        json: json,
                                    }
                                );
                            } else {
                                let errorMessge: string =
                                    result.errorCode +
                                    ': ' +
                                    result.errorMessage;
                                this.notifierService.showNotificationError(
                                    errorMessge
                                );
                                return fromActions.generatePDFFailed(
                                    {
                                        actionName: loadData.actionName
                                    }
                                );
                            }
                        }),
                        catchError((error) => {
                            this.notifierService.showNotificationError(error);
                            return of(
                                fromActions.generatePDFFailed({
                                    actionName: loadData.actionName
                                })
                            );
                        })
                    )
            )
        )
    );

    public sendMail: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(fromActions.sendMail),
            mergeMap((loadData) =>
                this.dynamicUiService
                    .ActionSendMail(loadData.uiAction)
                    .pipe(
                        map((result) => {
                            if (result.success && result.success == true) {
                                console.log('sendMail: ', result)
                                // TODO Marco: Was soll zurückgegeben werden??? z.B. Zeitpunkt der Aktion ??? ev. in DB mittels SP eintragen ???
                                return null;
                            } else {
                                let errorMessge: string =
                                    result.errorCode +
                                    ': ' +
                                    result.errorMessage;
                                this.notifierService.showNotificationError(
                                    errorMessge
                                );
                                return fromActions.sendMailFailed(
                                    {
                                        actionName: loadData.actionName
                                    }
                                );
                            }
                        }),
                        catchError((error) => {
                            this.notifierService.showNotificationError(error);
                            return of(
                                fromActions.sendMailFailed({
                                    actionName: loadData.actionName
                                })
                            );
                        })
                    )
            )
        )
    );
}
