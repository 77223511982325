import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { NotifierService } from '../../services/notifier.service';
import { HeaderMenu } from '../../models/header-menu.models';
import { AuthFacade } from 'src/app/auth/store/auth.facade';

@Component({
  selector: 'app-header-menu-config',
  templateUrl: './header-menu-config.component.html',
  styleUrls: ['./header-menu-config.component.scss']
})
export class HeaderMenuConfigComponent {

  headerMenus$ = this.authFacase.headerMenus$;

  constructor(private authFacase: AuthFacade) {
  }

}
