import { ActionReducer, Action } from '@ngrx/store';
import { merge, pick, isEmpty } from 'lodash-es';
import { DYNAMIC_FEATURE_KEY } from './dynamic.state';

function setSavedState(state: any, sessionStorageKey: string) {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(state));
}
function getSavedState(sessionStorageKey: string): any {
    return JSON.parse(sessionStorage.getItem(sessionStorageKey));
}

// the keys from state which we'd like to save.
const stateKeys = ['session'];
// the key for the local storage.
const sessionStorageKey = '__ea_session__';

export function dynamicMetaReducer<S, A extends Action = Action>(reducer: ActionReducer<S, A>) {
    let onInit = true; // after load/refresh…
    return function (state: S, action: A): S {
        //console.log('before', action, state);
        const nextState = reducer(state, action);
        if (onInit) {
            onInit = false;
            const savedState = getSavedState(sessionStorageKey);
            return merge(nextState, savedState);
        }

        const stateToSave = pick(nextState, stateKeys);
        setSavedState(stateToSave, sessionStorageKey);
        //console.log('after', nextState);
        return nextState;
    };
}