import { UiComponent } from "./ui-component.models";

export class SpRequestBodyInputParameter {
  name: string;
  sqlDataType: string;
  value: any;

  constructor(name: string, sqlDataType: string, value: any) {
    this.name = name;
    this.sqlDataType = sqlDataType;
    this.value = value;
  }
}