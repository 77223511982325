import { SpRequestBodyInputParameter } from "./sp-request-body-input-parameter.models"

export class ExecuteSpRequest {
  spName: string;
  inputParameters: SpRequestBodyInputParameter[];

  constructor(spName: string) {
    this.spName = spName;
    this.inputParameters = new Array<SpRequestBodyInputParameter>();
  }
}
