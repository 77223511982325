import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { AuthFacade } from 'src/app/auth/store/auth.facade';
import { AppFacade } from '../../store/app.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  routerNavigationStartSubscription: Subscription;
  routerRoutesRecognizedSubscription: Subscription;

  constructor(
    private appFacade: AppFacade,
    private authFacade: AuthFacade,
    private router: Router,
  ) {
    this.routerNavigationStartSubscription = this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((event) => {
      this.authFacade.getAuthUser();
    });
    this.routerRoutesRecognizedSubscription = this.router.events.pipe(filter(e => e instanceof RoutesRecognized)).subscribe((event) => {
      this.appFacade.SetTitle((event as RoutesRecognized).state.root.firstChild.data.title);
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.routerNavigationStartSubscription.unsubscribe();
    this.routerRoutesRecognizedSubscription.unsubscribe();
  }
}
