import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import {
    catchError,
    exhaustMap,
    map,
    tap,
} from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import * as AuthActions from './auth.actions';
import { NotifierService } from 'src/app/app/services/notifier.service';
import { AuthFacade } from './auth.facade';
import { ResponseError } from './auth.state';
import { DynamicUiService } from 'src/app/dynamic-ui/services/dynamic-ui.service';
import { AppConstants } from 'src/app/app/helpers/app-constants';

@Injectable()
export class AuthEffects {
    constructor(
        private router: Router,
        private actions$: Actions,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private notifierService: NotifierService,
        private dynamicService: DynamicUiService
    ) {}

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.login),
            exhaustMap((credentials) =>
                this.authService
                    .login(
                        credentials.username,
                        credentials.password,
                        credentials.rememberThisUser
                    )
                    .pipe(
                        map((response) => {
                            return AuthActions.loginSuccess({ response });
                        }),
                        catchError((error) => {
                            console.log('effect login catcherror');
                            console.log(error);
                            return of(
                                AuthActions.loginFailure({
                                    error: {
                                        ...new ResponseError(
                                            error.message,
                                            error.statusCode
                                        ),
                                        error: error.error,
                                    },
                                })
                            );
                        })
                    )
            )
        );
    });

    onLoginSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.loginSuccess),
            map((response) => {
                this.authService.setSessionGuid(response.response.sessionGuid);
                // redirect to return url or home
                this.router.navigateByUrl(
                    this.activatedRoute.snapshot.queryParams.returnUrl || '/'
                );
                return AuthActions.getAuthUserRequest();
            })
        );
    });

    logout$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.logout),
                exhaustMap(() =>
                    this.authService.logout().pipe(
                        map(() => {
                            return this.router.navigateByUrl('/');
                        }),
                        catchError((error) =>
                            of(
                                AuthActions.refreshTokenFailure({
                                    error: error,
                                })
                            )
                        )
                    )
                )
            );
        },
        { dispatch: false }
    );

    getUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                AuthActions.refreshTokenSuccess,
                AuthActions.getAuthUserRequest
            ),
            exhaustMap(() =>
                this.authService.getAuthUser().pipe(
                    map((user) => {
                        // Check if session for selectedClub ist set
                        var currentClub = this.dynamicService.GetValue(
                            AppConstants.dataStoreSelectedClub
                        );
                        if (!(currentClub?.id > 0)) {
                            this.dynamicService.SetValue(
                                AppConstants.dataStoreSelectedClub,
                                user.firstClub
                            );
                        }
                        return AuthActions.getAuthUserSuccess({ user });
                    }),
                    catchError(() => of(AuthActions.getAuthUserFailure()))
                )
            )
        );
    });

    refreshToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.refreshToken),
            exhaustMap(() =>
                this.authService.refreshToken().pipe(
                    map(() => {
                        return AuthActions.refreshTokenSuccess();
                    }),
                    catchError((error) =>
                        of(AuthActions.refreshTokenFailure(error))
                    )
                )
            )
        );
    });

    onLoginOrRefreshTokenFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    AuthActions.loginFailure,
                    AuthActions.refreshTokenFailure
                ),
                tap((_) => {
                    console.log('effect');
                    console.log(_);
                    console.log(_.error);
                    this.notifierService.showNotificationError(
                        _.error.error?.errorMessage ?? _.error.message
                    );
                    //this.authFacade.logout();
                })
            );
        },
        { dispatch: false }
    );

    onSignUp$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.signup),
            exhaustMap((credentials) =>
                this.authService.signUp(credentials.signUpRequest).pipe(
                    map((data) => {
                        return AuthActions.signUpSuccess();
                    }),
                    catchError((error) =>
                        of(AuthActions.signUpFailure({ error }))
                    )
                )
            )
        );
    });

    onSignUpFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AuthActions.signUpFailure),
                tap((error) => {
                    this.notifierService.showNotificationError(
                        error.error.error?.errorMessage ?? error.error.message
                    );
                })
            );
        },
        { dispatch: false }
    );

}
