import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthFacade } from '../../store/auth.facade';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public loginValid = true;
  public username = '';
  public password = '';
  public rememberThisUser = false;

  isLoading$ = this.authFacade.isLoadingLogin$;
  showLoginError$ = this.authFacade.hasLoginError$;

  constructor(private authFacade: AuthFacade) { }

  onSubmit() {
    this.authFacade.login(this.username, this.password, this.rememberThisUser);
  }

  onRememberThisUserChange(checkBox: MatCheckboxChange) {
    this.rememberThisUser = checkBox.checked;
  }
}
