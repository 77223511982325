import { AfterViewInit, Component, ComponentRef, Injector, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent extends DynamicBaseClass implements OnInit, OnDestroy, AfterViewInit {

  private componentRef: ComponentRef<DynamicBaseClass>;
  @ViewChildren('children', { read: ViewContainerRef }) viewChildrenRef: QueryList<ViewContainerRef>;

  public title$: Observable<string>;
  public description$: Observable<string>;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.title$ = this.GetTitleFromConfig();
    this.description$ = this.GetDescriptionFromConfig();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (this.children) {
      setTimeout(() => {
        this.AddComponentsFromChildren(this.viewChildrenRef, this.componentRef);
      });
    }
  }

}

