import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn, ActivatedRoute, mapToCanActivate, UrlTree
} from '@angular/router';
import { Observable, catchError, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { selectIsLoggedIn, selectIsLoggedInChecked } from '../store/auth.selectors';
import { Store } from '@ngrx/store';
import { AuthService } from '../services';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const store = inject(Store);
  const authService = inject(AuthService);

  return store.select(selectIsLoggedInChecked).pipe(
    mergeMap(loggedInChecked => {
      if (loggedInChecked) {
        return store.select(selectIsLoggedIn).pipe(
          map(isLoggedIn => {
            if (isLoggedIn) {
              return true;
            }
            else {
              return router.createUrlTree(["/login"], {
                queryParams: { loggedOut: true, returnUrl: state.url }
              });
            }
          }
          )
        );
      }
      else {
        return authService.getAuthUser().pipe(
          map(authUser => {
            if (authUser.mail && authUser.mail.length > 0) {
              if (authService.getSessionGuid() != authUser.sessionGuid) authService.setSessionGuid(authUser.sessionGuid);
              return true;
            }
            else {
              return router.createUrlTree(["/login"], {
                queryParams: { loggedOut: true, returnUrl: state.url }
              });
            }
          }
          )
        );
      }
    })
  );

  /*
       true :
       router.createUrlTree([router.parseUrl("/login")], {
      queryParams: { loggedOut: true, returnUrl: state.url }
    } )),
    catchError((err) => {
      router.navigate(['/login'], {
        queryParams: { loggedOut: true, returnUrl: state.url }
      });
      return of(false);
    })
  )

  */
}
