import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable, Subscription, of } from 'rxjs';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';
import { isEqual } from 'lodash-es';

@Component({
    selector: 'app-dynamic-drop-down',
    templateUrl: './drop-down.component.html',
    styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent
    extends DynamicBaseClass
    implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('select') select: MatSelect;

    public value$: Observable<any>;
    public title$: Observable<string>;
    public dataSource$: Observable<any>;

    dataSourceSubscription: Subscription;
    dataSource: any[] = [];
    keyPath: string;
    displayPath: string;
    selectedValue: any = undefined;
    selectedValues: any[] = [];
    selectedStoreData: string;
    withEmptyFirstLine: boolean;
    withSelection: boolean;
    withUniqueDisplay: boolean;
    selectFirstEntry: boolean;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.value$ = this.GetValueFromConfig();
        this.title$ = this.GetTitleFromConfig();
        if (this.config) {
            this.selectedStoreData = this.config.selectedStoreData;
            this.keyPath =
                this.config.dataStoreKeyForValue;
            this.displayPath =
                this.config.dataStoreKeyForDisplay ??
                this.keyPath;
            this.withEmptyFirstLine = this.config.withEmptyFirstLine ?? false;
            this.withSelection = this.config.withSelection ?? false;
            this.withUniqueDisplay = this.config.withUniqueDisplay ?? false;
            this.selectFirstEntry = this.config.selectFirstEntry ?? false;

            this.dataSource$ = of(this.dataSource);
            // Wenn der Inhalte des Quell-Stores sich ändert
            this.dataSourceSubscription = this.value$.subscribe((c) => {
                this.dataSource = [];
                this.selectedValues = [];
                this.selectedValue = undefined;
                if (c) {
                    c.forEach((d: { [x: string]: any }) => {
                        var keyValue = undefined;
                        var displayValue = '';
                        if (this.keyPath === '.') {
                            keyValue = d;
                        } else {
                            keyValue =
                                d[
                                Object.keys(d).find(
                                    (key) =>
                                        key.toLowerCase() ===
                                        this.keyPath.toLowerCase()
                                )
                                ];
                        }
                        if (this.displayPath) {
                            displayValue =
                                d[
                                Object.keys(d).find(
                                    (key) =>
                                        key.toLowerCase() ===
                                        this.displayPath.toLowerCase()
                                )
                                ];
                        } else {
                            displayValue = keyValue;
                        }
                        if (!this.withUniqueDisplay || !this.dataSource.find((item) => item.display === displayValue)) {
                            this.dataSource.push({
                                key: keyValue,
                                display: displayValue,
                            });
                        }
                    });
                    this.dataSource.sort((a, b) => (a.display as string).localeCompare((b.display as string)));
                }
                this.dataSource$ = of(this.dataSource);

                setTimeout(() => {
                    if (this.selectFirstEntry && this.select?.options && this.select.options.length > 0) {
                        this.select.options.first.select();
                    }
                });
            });
        }

        if (this.selectedStoreData) {
            if (this.withSelection) {
                this.selectedValues = this.dynamicUiServices.GetValue(
                    this.selectedStoreData
                );
            }
            else {
                this.selectedValue = this.dynamicUiServices.GetValue(
                    this.selectedStoreData
                );
            }
        }
        if (!this.withSelection && !this.selectedStoreData && this.dataSource.length > 0) {
            this.selectedValue = this.dataSource[0].keyValue ?? undefined;
        }
        
        if (this.selectedStoreData) {
            this.dynamicUiServices.GetValueAsObservable(this.selectedStoreData).subscribe(c => {
                if (this.withSelection) {
                    if (c && Array.isArray(c) && c.length > 0) {
                        if (this.selectedValues != c) {
                            this.selectedValues = c;
                        }
                    }
                    else {
                        this.selectedValues = [];
                        if (this.select?.options && this.select.options.length > 0) {
                            this.select.options.forEach((item: MatOption) => item.deselect());
                        }
                    }
                }
                else {
                    if (c) {
                        if (this.selectedValue != c) {
                            this.selectedValue = c;
                        }
                    }
                    else {
                        this.selectedValue = "";
                    }
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.dataSourceSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.selectFirstEntry && this.select?.options && this.select?.options.length > 0) {
                this.select.options.first.select();
            }
        });
    }

    onModelChangeMultiple(event: MatSelectChange) {
        this.selectedValues = event.source.triggerValue.split(',');
        if (this.selectedStoreData) {
            this.dynamicUiServices.SetValue(
                this.selectedStoreData,
                event.value
            );
        }
    }

    onModelChange(event: any) {
        this.selectedValue = event;
        if (this.selectedStoreData) {
            this.dynamicUiServices.SetValue(
                this.selectedStoreData,
                event
            );
        }
    }

    compareSingle(o1: any, o2: any): boolean {
        return isEqual(o1, o2);
    }
}
