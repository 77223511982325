import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    Inject,
    Injector
} from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-dynamic-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent
    extends DynamicBaseClass
    implements OnInit, OnDestroy {

    public value$: Observable<string>;
    public title$: Observable<string>;
    readonly: boolean = false;
    textBefore: boolean = false;
    checked: boolean = false;

    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.value$ = this.GetValueFromConfig();
        this.title$ = this.GetTitleFromConfig();
        if (this.config) {
            this.textBefore = this.config.textBefore ?? false;
            this.readonly = this.config.readonly ?? false;
        }
        this.value$.subscribe(c => (c === '1') ? this.checked = true : this.checked = false );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    onModelChange(value: string) {
        this.SetValueFromConfig(this.checked ? '1' : '0');
    }
}
