export function ConvertStoreDataToElements(storeData: string): [string, string, string] {
    const splitSource = storeData.split('.');
    if (splitSource.length < 2) {
        console.error("StoreData ist nicht gültig : ", storeData);
    }

    const store = splitSource[0];
    const storeDataName = splitSource[1];
    const storeDataPath = storeData.slice(splitSource[0].length + splitSource[1].length + 2);

    return [store, storeDataName, storeDataPath];
}
