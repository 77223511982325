import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthFacade } from '../../store/auth.facade';

@Component({
  selector: 'app-auth-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  public loginValid = true;
  public username = '';
  public password = '';
  public passwordConfirm = '';

  isLoading$ = this.authFacade.isLoadingLogin$;
  showLoginError$ = this.authFacade.hasLoginError$;

  constructor(private authFacade: AuthFacade) { }

  onSubmit() {
    this.authFacade.signUp(this.username, this.password, this.passwordConfirm);
  }
}
