import { AfterViewInit, Component, ComponentRef, Injector, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';

@Component({
  selector: 'app-dynamic-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss']
})
export class ColumnComponent extends DynamicBaseClass implements OnInit, OnDestroy, AfterViewInit {

  private componentRef: ComponentRef<DynamicBaseClass>;
  @ViewChildren('children', { read: ViewContainerRef }) viewChildrenRef: QueryList<ViewContainerRef>;

  protected width: string = undefined;
  protected textAlign: string = undefined;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.config) {
      if (this.config.width) {
        this.width = this.config.width;
      }
      if (this.config.textAlign) {
        this.textAlign = this.config.textAlign;
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (this.children) {
      setTimeout(() => {
        this.AddComponentsFromChildren(this.viewChildrenRef, this.componentRef);
      });
    }
  }

}

