import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './dynamic.actions';
import { initialDynamicState } from './dynamic.state';
import { AddOrUpdateData, RemoveData, GetActionsFromUiDefinition } from 'src/app/shared/helpers/store-helper';

const _dynamicReducer = createReducer(
    initialDynamicState,

    on(fromActions.initLocalStore, (state, action) => ({
        ...initialDynamicState,
        session: [...state.session],
        actions: GetActionsFromUiDefinition(action.uiDefinition)
    })),

    on(fromActions.saveDataInStore, (state, action) => {
        let newState = {...state};
        if (action.storeName.toLowerCase() === "local")
        {
            newState.local = AddOrUpdateData(newState.local, action.storeDataName, action.storeDataPath, action.newValue);
        }
        else if (action.storeName.toLowerCase() === "session")
        {
            newState.session = AddOrUpdateData(newState.session, action.storeDataName, action.storeDataPath, action.newValue);
        }
        return (newState);
    }),

    on(fromActions.removeDataInStore, (state, action) => {
        let newState = {...state};
        if (action.storeName.toLowerCase() === "local")
        {
            newState.local = RemoveData(newState.local, action.storeDataName, action.storeDataPath);
        }
        else if (action.storeName.toLowerCase() === "session")
        {
            newState.session = RemoveData(newState.session, action.storeDataName, action.storeDataPath);
        }
        return (newState);
    }),

)

export function dynamicReducer(state = initialDynamicState, action: Action) {
    return _dynamicReducer(state, action);
}



