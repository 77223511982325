@if (withMultiple){
<mat-accordion multi>
    <ng-container *ngFor="let child of children">
        <ng-template #children></ng-template>
    </ng-container>
</mat-accordion>
} @else {
<mat-accordion>
    <ng-container *ngFor="let child of children">
        <ng-template #children></ng-template>
    </ng-container>
</mat-accordion>
}