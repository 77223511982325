import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from "@angular/common";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';

import { DynamicUiRoutingModule } from './dynamic-ui-routing.module';
import { DYNAMIC_FEATURE_KEY } from './store/dynamic.state';
import { DynamicEffects } from './store/dynamic.effects';
import { dynamicReducer } from './store/dynamic.reducer';
import { DynamicFacade } from './store/dynamic.facade';
import { dynamicMetaReducer } from './store/dynamic.metareducer';
// Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTreeModule } from "@angular/material/tree";
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
// Dynamic Controls
import { RowComponent } from './controls/row/row.component';
import { ColumnComponent } from './controls/column/column.component';
import { LabelComponent } from './controls/label/label.component'
import { LabelWithTitleComponent } from './controls/label-with-title/label-with-title.component'
import { TabComponent } from './controls/tab/tab.component'
import { LinkComponent } from './controls/link/link.component'
import { InputComponent } from './controls/input/input.component'
import { DropDownComponent } from './controls/drop-down/drop-down.component'
import { TableComponent } from './controls/table/table.component'
import { TreeComponent } from './controls/tree/tree.component'
import { ButtonComponent } from './controls/button/button.component'
import { CardComponent } from './controls/card/card.component'
import { GridComponent } from './controls/grid/grid.component'
import { GridItemComponent } from './controls/grid-item/grid-item.component'
import { TitleComponent } from './controls/title/title.component'
import { ExpansionPanelComponent } from './controls/expansion-panel/expansion-panel.component'
import { AccordionComponent } from './controls/accordion/accordion.component'
import { DividerComponent } from './controls/divider/divider.component'
import { CheckboxComponent } from './controls/checkbox/checkbox.component'
import { PdfViewerComponent } from './controls/pdf-viewer/pdf-viewer.component'
import { ObjectToJsonPipe } from 'src/app/shared/pipes/object-to-json.pipe';

@NgModule({
  declarations: [
    RowComponent,
    ColumnComponent,
    LabelComponent,
    LabelWithTitleComponent,
    TabComponent,
    LinkComponent,
    InputComponent,
    DropDownComponent,
    TableComponent,
    TreeComponent,
    ButtonComponent,
    CardComponent,
    GridComponent,
    GridItemComponent,
    TitleComponent,
    ExpansionPanelComponent,
    AccordionComponent,
    DividerComponent,
    CheckboxComponent,
    PdfViewerComponent,
    ObjectToJsonPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DynamicUiRoutingModule,
    FormsModule,
    // Material
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatTableModule,
    MatTreeModule,
    MatSortModule,
    MatDividerModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatDatepickerModule,
    // ngrx
    StoreModule.forFeature(DYNAMIC_FEATURE_KEY, dynamicReducer, { metaReducers: [dynamicMetaReducer] }),
    EffectsModule.forFeature(DynamicEffects),
  ],
  exports: [
    RowComponent,
    ColumnComponent,
    LabelComponent
  ],
  providers: [
    DynamicFacade,
    provideNativeDateAdapter()
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class DynamicUiModule { }
