import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent extends DynamicBaseClass implements OnInit, OnDestroy {

  public value$: Observable<string>;
  public url$: Observable<string>;
  public class$: Observable<string>;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.value$ = this.GetValueFromConfig();
    this.class$ = this.GetClassFromConfig();
    this.url$ = this.GetUrlFromConfig();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}


