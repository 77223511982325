import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AuthModule } from './auth/auth.module';
import { DynamicUiModule } from './dynamic-ui/dynamic-ui.module';

import { AppComponent } from './app/views/app/app.component';
import { AppRoutingModule } from "./app-routing.module";
import { AppFacade } from './app/store/app.facade';
import { HeaderComponent } from './app/controls/header/header.component';
import { HeaderMenuConfigComponent } from './app/controls/header-menu-config/header-menu-config.component';
import { HeaderMenuSprintComponent } from './app/controls/header-menu-sprint/header-menu-sprint.component';
import { HeaderMenuUserComponent } from './app/controls/header-menu-user/header-menu-user.component';

import { HomeComponent } from './app/views/home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AppEffects } from './app/store/app.effects';
import { appReducer } from './app/store/app.reducer';
import { NotifierComponent } from './app/controls/notifier/notifier.component';
// Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from "@angular/material/divider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatTreeModule } from "@angular/material/tree";

import { DynamicComponent } from './dynamic-ui/views/dynamic/dynamic.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderMenuConfigComponent,
    HeaderMenuSprintComponent,
    HeaderMenuUserComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    NotifierComponent,
    DynamicComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    // Material
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatSnackBarModule,
    MatTableModule,
    MatTreeModule,
    // ngrx
    StoreModule.forRoot({
      app: appReducer
    },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: true,
          strictActionSerializability: true
        }
      }),
    EffectsModule.forRoot(AppEffects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() , connectInZone: true}),
    AuthModule,
    AppRoutingModule,
    DynamicUiModule,
  ],
  providers: [
    AppFacade,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
