import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as fromActions from './app.actions';
import * as fromAuthActions from '../../auth/store/auth.actions';
import { ClubService } from '../../club/services/club.service';

@Injectable({
  providedIn: 'root'
})
export class AppEffects {
  constructor(
    private actions: Actions,
    private clubService: ClubService) {
  }

}