<ng-container *ngIf="uiDefinition else noUiDefinitionFound">
    <ng-container *ngFor="let component of uiDefinition.definition">
        <ng-template #children></ng-template>
    </ng-container>
</ng-container>

<ng-template #noUiDefinitionFound>
    <div>
        UiDefinition nicht gefunden.
    </div>
</ng-template>