import {
    Component,
    ComponentRef,
    Injector,
    Input,
    OnDestroy,
    QueryList,
    ViewContainerRef,
} from '@angular/core';
import * as ts from "typescript";
import { UiComponent } from '../../models/ui-component.models';
import { DynamicFacade } from '../../store/dynamic.facade';
import { DynamicUiService } from '../../services/dynamic-ui.service';
import { Observable, Subscription, of } from 'rxjs';

@Component({
    template: '',
})
export abstract class DynamicBaseClass implements OnDestroy {
    @Input() config!: any;
    @Input() children!: UiComponent[];

    protected dynamicUiServices: DynamicUiService;
    protected dynamicFacade: DynamicFacade;
    protected viewCondition: boolean = true;
    protected viewConditionSubscribers: { id: string, subscriber: Subscription }[] = [];

    private viewConditionEval: string;

    constructor(private injector: Injector) {
        this.dynamicUiServices = injector.get(DynamicUiService);
        this.dynamicFacade = injector.get(DynamicFacade);
    }

    ngOnDestroy(): void {
        this.viewConditionSubscribers.forEach(c => {
            if (c.subscriber) {
                c.subscriber.unsubscribe();
            }
        })
    }

    protected AddComponentsFromChildren(
        viewChildrenRef: QueryList<ViewContainerRef>,
        componentRef: ComponentRef<DynamicBaseClass>
    ) {
        viewChildrenRef.map((vcr: ViewContainerRef, index: number) => {
            vcr.clear();
            const component = this.dynamicUiServices.GetComponent(
                this.children[index]
            )?.component;
            if (component) {
                componentRef = vcr.createComponent(component);
                componentRef.instance.children = this.children[index].children;
                componentRef.instance.config = this.children[index].config;
            }
        });
    }

    protected GetValueFromConfig(): Observable<any> {
        if (this.config) {
            if (this.config.sourceStoreData) {
                return this.dynamicUiServices.GetValueAsObservable(this.config.sourceStoreData);
            } else if (this.config.storeData) {
                return this.dynamicUiServices.GetValueAsObservable(this.config.storeData);
            } else if (this.config.staticValue) {
                return of(this.config.staticValue);
            }
        }
        return of('');
    }

    protected GetTitleFromConfig(): Observable<string> {
        if (this.config) {
            if (this.config.title) {
                return of(this.config.title);
            }
        }
        return of('');
    }

    protected GetSubtitleFromConfig(): Observable<string> {
        if (this.config) {
            if (this.config.subtitle) {
                return of(this.config.subtitle);
            }
        }
        return of('');
    }

    protected GetDescriptionFromConfig(): Observable<string> {
        if (this.config) {
            if (this.config.description) {
                return of(this.config.description);
            }
        }
        return of('');
    }

    protected GetColorFromConfig(): Observable<string> {
        if (this.config) {
            if (this.config.color) {
                return of(this.config.color);
            }
        }
        return of('');
    }

    protected GetClassFromConfig(defaultClass: string = undefined): Observable<string> {
        if (this.config) {
            if (this.config.class) {
                return of(this.config.class);
            }
        }
        return of(defaultClass);
    }

    protected GetUrlFromConfig(): Observable<string> {
        if (this.config) {
            if (this.config.staticUrl) {
                return of(this.config.staticUrl);
            }
        }
        return of('');
    }

    SetValueFromConfig(newValue: string) {
        if (this.config) {
            if (this.config.targetStoreData) {
                this.dynamicUiServices.SetValue(this.config.targetStoreData, newValue);
            } else if (this.config.storeData) {
                this.dynamicUiServices.SetValue(this.config.storeData, newValue);
            }
        }
    }

    ConvertIcon(icon: string): string {
        if (icon) {
            switch (icon.toLowerCase()) {
                case "action": { return "flash_on" };
                case "control": { return "event_outline" };
                case "anlass": { return "event" };
                case "event": { return "emoji_events" };
                case "person": { return "person" };
                case "group": { return "group" };
                case "rangliste": { return "format_list_numbered" };
                case "praesentation": { return "dvr" };
            }
        }
        return null;
    }

    protected GlobalConfig() {
        if (this.config.viewCondition) {
            this.viewConditionEval = this.config.viewCondition;
            this.executeViewConditionEval();
        }
    }

    executeViewConditionEval() {
        this.viewCondition = eval(ts.transpile(this.viewConditionEval)) as boolean;
    }

    // Funktion für viewCondition, diese Funktion sollte nicht ausserhalb verwendet werden.
    protected getDataStore(dataStore: string): any {
        this.AddDataStoreToObservable(dataStore);
        return this.dynamicUiServices.GetValue(dataStore);
    }

    private AddDataStoreToObservable(dataStore: string) {
        if (this.viewConditionSubscribers.findIndex(s => s.id?.toLowerCase() === dataStore?.toLowerCase()) == -1) {
            this.viewConditionSubscribers.push({ id: dataStore, subscriber: null });
            const subscriber = this.dynamicUiServices.GetValueAsObservable(dataStore).subscribe(c => {
                this.executeViewConditionEval();
            });
            this.viewConditionSubscribers.find(s => s.id?.toLowerCase() === dataStore?.toLowerCase())["subscriber"] = subscriber;
        }
    }
}


