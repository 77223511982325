import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends DynamicBaseClass implements OnInit, OnDestroy {

  public value$: Observable<string>;
  public title$: Observable<string>;
  color$: Observable<string>;

  onClickExecuteActionName: string;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.title$ = this.GetTitleFromConfig();
    this.color$ = this.GetColorFromConfig();
    if (this.config) {
      this.onClickExecuteActionName = this.config.onClickExecuteActionName
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onClick() {
    this.dynamicFacade.ExecuteAction(this.onClickExecuteActionName);
  }

}

