<mat-toolbar class="mat-elevation-z6">
  <img src="./assets/ea-logo.png" />
  <div>
    <div class="title">{{title$ | async}}</div>
    <div class="subtitle caption">{{subTitle}}</div>
  </div>
  <div class="header-spacer"></div>
  <app-header-menu-sprint></app-header-menu-sprint>
  <app-header-menu-config></app-header-menu-config>
  <app-header-selector></app-header-selector>
</mat-toolbar>