import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as AppActions from './app.actions';
import * as AppSelectors from './app.selectors';
import { AppState } from './app.state';

@Injectable()
export class AppFacade {
  
  app$ = this.appStore.select(AppSelectors.appQuery);
  title$ =  this.appStore.select(AppSelectors.getTitle);

  constructor(private appStore: Store<AppState>) { }

  SetTitle(title: string) {
    this.appStore.dispatch(AppActions.setTitle({title: title}));
  }

}
