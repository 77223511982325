import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DYNAMIC_FEATURE_KEY, DynamicState } from './dynamic.state';

export const dynamicQuery = createFeatureSelector<DynamicState>(DYNAMIC_FEATURE_KEY);
export const localStore = (state: DynamicState) => state.local;
export const sessionStore = (state: DynamicState) => state.session;

export const getStoreData = (storeName: string, storeDataName: string, storeDataPath: string) => createSelector(
    dynamicQuery,
    (state: DynamicState) => {
        var store = undefined;
        if (storeName.toLowerCase() === 'local') store = state.local;
        if (storeName.toLowerCase() === 'session') store = state.session;
        if (store) {
            var obj = store.find(c => (c.name as string).toLowerCase() === storeDataName.toLowerCase())?.value;
            if (obj) {
                if (storeDataPath !== '.' && storeDataPath !== '') {
                    if (storeDataPath.startsWith('.')) storeDataPath = storeDataPath.slice(1); //line is obsolete
                    for (var i = 0, path = storeDataPath.split('.').filter(j => j), len = path.length; i < len; i++) {
                        obj = obj[Object.keys(obj).find(key => key.toLowerCase() === path[i].toLowerCase())];
                    }
                }
                return obj;
            }
        }
        return null;
    }
);

export const getActions = createSelector(
    dynamicQuery,
    (state: DynamicState) => state.actions
);

export const getAction = (name: string) => createSelector(
    dynamicQuery,
    (state: DynamicState) => {
        return state.actions.find(c => (c.name as string).toLowerCase() === name.toLowerCase());
    }
);
