import { AfterViewInit, Component, ComponentRef, Injector, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';

@Component({
  selector: 'app-dynamic-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss']
})
export class DividerComponent extends DynamicBaseClass implements OnInit, OnDestroy, AfterViewInit {

  private componentRef: ComponentRef<DynamicBaseClass>;
  @ViewChildren('children', { read: ViewContainerRef }) viewChildrenRef: QueryList<ViewContainerRef>;

  minWidth: string;
  minHeight: string;
  verticalAlign: string;
  horizontalAlign: string;
  class: string = "";
  addClass: string;

  constructor(
    injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.config) {
      this.minWidth = this.config.minWidth;
      this.minHeight = this.config.minHeight;
      this.verticalAlign = this.config.verticalAlign;
      this.horizontalAlign = this.config.horizontalAlign;
      this.horizontalAlign = this.config.horizontalAlign;
      this.addClass = this.config.class;
      super.GlobalConfig();
    }
    if (this.verticalAlign) this.class = "vertical_" + this.verticalAlign;
    if (this.horizontalAlign) this.class += " horizontal_" + this.horizontalAlign;
    if (this.addClass) this.class += " " + this.addClass;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (this.children) {
      setTimeout(() => {
        this.AddComponentsFromChildren(this.viewChildrenRef, this.componentRef);
      });
    }
  }

}

