import { SpRequestBodyInputParameter } from "./sp-request-body-input-parameter.models"

export class GeneratePDFRequest {
  templateName: string;
  parameter1: string;
  parameter2: string;
  parameter3: string;
  parameter4: string;
  parameter5: string;

  constructor(templateName: string, parameter1: string = "", parameter2: string = "", parameter3: string = "", parameter4: string = "", parameter5: string = "") {
    this.templateName= templateName;
    this.parameter1 = parameter1;
    this.parameter2 = parameter2;
    this.parameter3 = parameter3;
    this.parameter4 = parameter4;
    this.parameter5 = parameter5;
  }
}
