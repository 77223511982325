<mat-card class="logon_card">
  <mat-card-content>
    <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
      <h2>Registrierung</h2>
      <mat-error *ngIf="!loginValid">
        The username and password were not recognized
      </mat-error>
      <mat-form-field class="fill_width">
        <input matInput placeholder="Email oder SSV-Lizenznummer" [(ngModel)]="username" name="username" required>
        <mat-error>
          Bitte gib eine erlaubte Email-Adresse oder eine gültige SSV-Lizenznummer an
        </mat-error>
      </mat-form-field>
      <mat-form-field class="fill_width">
        <input matInput type="password" placeholder="Passwort" [(ngModel)]="password" name="password" required>
        <mat-error>
          Bitte gib ein gültiges Passwort ein
        </mat-error>
      </mat-form-field>
      <mat-form-field class="fill_width">
        <input matInput type="password" placeholder="Passwort bestätigen" [(ngModel)]="passwordConfirm" name="passwordconfirm" required>
        <mat-error>
          Bitte gib ein gültiges Passwort ein
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" [disabled]="!loginForm.form.valid">Registrieren</button>
    </form>
  </mat-card-content>
</mat-card>