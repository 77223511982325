import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { NotifierService } from '../../services/notifier.service';
import { HeaderMenu } from '../../models/header-menu.models';
import { AuthFacade } from 'src/app/auth/store/auth.facade';

@Component({
  selector: 'app-header-menu-sprint',
  templateUrl: './header-menu-sprint.component.html',
  styleUrls: ['./header-menu-sprint.component.scss']
})
export class HeaderMenuSprintComponent {

  headerMenus$ = this.authFacase.headerMenus$;

  constructor(private authFacase: AuthFacade) {
  }

}
