import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VereinSmall } from 'src/app/app/models/verein-small.models';
import { AuthFacade } from 'src/app/auth/store/auth.facade';

@Component({
  selector: 'app-header-selector',
  templateUrl: './header-menu-user.component.html',
  styleUrls: ['./header-menu-user.component.scss']
})
export class HeaderMenuUserComponent {

  loggedInUser$ = this.authFacade.loggedInUser$;
  isLoggedIn$ = this.authFacade.isLoggedIn$;

  constructor(
    private authFacade: AuthFacade
    ) {
  }

  onLogout() {
    this.authFacade.logout();
  }

}
