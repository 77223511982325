<div [hidden]="!viewCondition">
    <mat-card>
        <mat-card-header style="display: flex;">
            <mat-card-title-group style="flex: 1 1 auto;">
                <mat-card-title>{{title$ | async}}</mat-card-title>
                <mat-card-subtitle>{{subtitle$ | async}}</mat-card-subtitle>
            </mat-card-title-group>
            <button *ngIf="onSaveClickExecuteActionName" (click)="onSaveClick()" mat-mini-fab color="primary"
                style="flex-shrink: 0;" class="margin-bottom-1" aria-label="Speichern">
                <mat-icon>save</mat-icon>
            </button>
        </mat-card-header>
        <mat-card-content style="display: block;">
            <ng-container *ngFor="let child of children">
                <ng-template #children></ng-template>
            </ng-container>
        </mat-card-content>
    </mat-card>
</div>