
export class SendMailRequest {
  //templateName: string;
  sender: string;
  recipients: string[];
  subject: string;
  mailbody: string;

  constructor(sender: string =  "", recipients: string[], subject: string = "", mailbody: string = "") {
    this.sender = sender;
    this.recipients = recipients;
    this.subject = subject;
    this.mailbody = mailbody;
  }
}
