import {
    AfterViewInit,
    Component,
    Injector,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable, Subscription } from 'rxjs';
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
} from '@angular/material/tree';

interface TreeNodeFlat {
    name: string;
    icon?: string;
    id: string;
    parentId?: string;
    children?: TreeNodeFlat[];
    value?: any;
}

interface TreeFlatNode {
    expandable: boolean;
    name: string;
    level: number;
    icon: string;
    value?: any;
}

@Component({
    selector: 'app-dynamic-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
})
export class TreeComponent
    extends DynamicBaseClass
    implements OnInit, OnDestroy, AfterViewInit
{
    public value$: Observable<any>;
    public title$: Observable<string>;

    storeData: string;
    selectedStoreData: string;
    maxHeight: string;
    isFlatStoreData: boolean;

    dataSourceSubscription: Subscription;
    keyPath: string;
    displayPath: string;
    iconPath: string;
    flatIdPath: string;
    flatParentIdPath: string;
    convertIcon: boolean;
    activeNode: any;

    private _transformerTreeNodeFlat = (node: TreeNodeFlat, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            icon: node.icon,
            value: node.value,
        } as TreeFlatNode;
    };

    treeControl = new FlatTreeControl<TreeFlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transformerTreeNodeFlat,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    dataSource = new MatTreeFlatDataSource(
        this.treeControl,
        this.treeFlattener
    );

    constructor(injector: Injector) {
        super(injector);
        //this.dataSource.data = TREE_DATA;
    }

    ngOnInit(): void {
        if (this.config) {
            this.storeData = this.config.storeData;
            this.selectedStoreData = this.config.selectedStoreData;
            this.keyPath = this.config.dataStoreKeyForValue;
            this.displayPath =
                this.config.dataStoreKeyForDisplay ?? this.keyPath;
            this.iconPath = this.config.dataStoreKeyForIcon;
            this.maxHeight = this.config.maxHeight;

            this.isFlatStoreData = this.config.isFlatStoreData ?? false;
            this.flatIdPath = this.config.flatDataStoreId;
            this.flatParentIdPath = this.config.flatDataStoreParentId;
            this.convertIcon = this.config.convertIcon ?? false;
        }

        //this.dataSource = new MatTableDataSource<any>([]);

        this.value$ = this.GetValueFromConfig();
        this.dataSourceSubscription = this.value$.subscribe((value) => {
            console.log('TreeValue changed', value);
            if (value) {
                if (!this.isFlatStoreData) {
                    let treeNodeFlat = this.ConvertTreeToTreeNodeFlat(value);
                    console.log('treeNodeFlat 1 ', treeNodeFlat);
                    let constructedTree = this.buildTreeArray(treeNodeFlat);
                    console.log('ConstructedTree 1', constructedTree);
                    this.dataSource.data = constructedTree;
                } else {
                    let treeNodeFlat = this.ConvertFlatToTreeNodeFlat(value);
                    let constrcutedTree = this.buildTreeArray(treeNodeFlat);
                    console.log('ConstructedTree 2', constrcutedTree);
                    this.dataSource.data = constrcutedTree;
                }
            }
        });
    }

    private ConvertTreeToTreeNodeFlat(treeObj: any[]): TreeNodeFlat[] {
        let id = 1;
        let displayPath = this.displayPath?.toLowerCase();
        let iconPath = this.iconPath?.toLowerCase();

        function flattenChild(
            childObj: any,
            parentId: number,
            level: number
        ): TreeNodeFlat[] {
            let array: TreeNodeFlat[] = [];
            let childParentId: number = id;

            const childCopy = {} as TreeNodeFlat;
            childCopy.id = (id++).toString();
            childCopy.parentId = parentId?.toString();
            childCopy.children = null;
            childCopy.name =
                childObj[
                    Object.keys(childObj).find(
                        (key) => key.toLowerCase() === displayPath
                    )
                ];
            childCopy.icon =
                childObj[
                    Object.keys(childObj).find(
                        (key) => key.toLowerCase() === iconPath
                    )
                ];
            childCopy.value = { ...childObj };

            array.push(childCopy);

            if (childObj.children) {
                childObj.children.forEach((childObj2: any) => {
                    array = array.concat(
                        flattenChild(childObj2, childParentId, level + 1)
                    );
                });
            }

            return array;
        }

        let result: TreeNodeFlat[] = [];
        treeObj.forEach((obj) => {
            result = result.concat(flattenChild(obj, null, 0));
        });
        return result;
    }

    ngOnDestroy(): void {
        if (this.dataSourceSubscription) {
            this.dataSourceSubscription.unsubscribe();
        }
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {}

    hasChild = (_: number, node: TreeFlatNode) => node.expandable;

    private ConvertFlatToTreeNodeFlat(value: any[]): TreeNodeFlat[] {
        let result: TreeNodeFlat[] = [];
        value.forEach((element) => {
            const entry = {} as TreeNodeFlat;
            //entry.value = {...element[Object.keys(element).find((key) => key.toLowerCase() === this.keyPath.toLowerCase())] ?? null};
            if (this.keyPath === '.' || this.keyPath === '')
                entry.value = { ...element };
            else {
                entry.value =
                    element[
                        Object.keys(element).find(
                            (key) =>
                                key.toLowerCase() === this.keyPath.toLowerCase()
                        )
                    ];
            }
            entry.name =
                element[
                    Object.keys(element).find(
                        (key) =>
                            key.toLowerCase() === this.displayPath.toLowerCase()
                    )
                ];
            entry.icon =
                element[
                    Object.keys(element).find(
                        (key) =>
                            key.toLowerCase() === this.iconPath.toLowerCase()
                    )
                ];
            entry.id =
                element[
                    Object.keys(element).find(
                        (key) =>
                            key.toLowerCase() === this.flatIdPath.toLowerCase()
                    )
                ];
            entry.parentId =
                element[
                    Object.keys(element).find(
                        (key) =>
                            key.toLowerCase() ===
                            this.flatParentIdPath.toLowerCase()
                    )
                ];
            result.push(entry);
        });
        return result;
    }

    buildTreeArray(flatArray: TreeNodeFlat[]): TreeNodeFlat[] {
        const result: TreeNodeFlat[] = [];

        // Use reduce to create a nodeMap
        const nodeMap = flatArray.reduce((acc: any, item: TreeNodeFlat) => {
            acc[item.id] = { ...item, children: [] } as TreeNodeFlat;
            return acc;
        }, {});

        // Iterate through flatArray to build the tree
        flatArray.forEach((item) => {
            //console.log("*** 444", item, result);
            if (item.parentId === undefined || item.parentId === null) {
                result.push(nodeMap[item.id]);
            } else {
                //let itemEntry =  nodeMap[item.parentId];
                //console.log("*** ItemEntry: ", itemEntry, item);
                nodeMap[item.parentId].children.push(nodeMap[item.id]);
            }
        });

        return result;
    }

    getIcon(node: any): string {
        if (this.convertIcon) {
            return this.ConvertIcon(node?.icon ?? ('' as string));
        } else {
            return node?.icon ?? '';
        }
    }

    selectItem(node: any) {
        console.log("selectItem", node);
        if (this.selectedStoreData) {
            this.dynamicUiServices.SetValue(this.selectedStoreData, node);
        }
    }
}
