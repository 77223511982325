<ng-container *ngIf="(this.headerMenus$ | async).length > 0">
  <button mat-icon-button [matMenuTriggerFor]="headerMenuSprint" class="menu-icon"
    aria-label="Header menu sprint">
    <mat-icon>directions_run</mat-icon>
  </button>
  <mat-menu #headerMenuSprint="matMenu">
      <a mat-menu-item disabled="true" >
        <span>Anlass durchführen</span>
      </a>
  </mat-menu>
</ng-container>