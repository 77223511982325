import {
    AfterViewInit,
    Component,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';

type TableColumn = {
    type?: string;
    dataStoreKey?: string;
    headerTitle?: string;
    visible?: boolean;
    iconName?: string;
};

@Component({
    selector: 'app-dynamic-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent
    extends DynamicBaseClass
    implements OnInit, OnDestroy, AfterViewInit {
    public value$: Observable<any>;
    public filterStoreData$: Observable<any>;

    storeData: string;
    filterStoreData: string;
    maxHeight: string;
    withPagination: boolean;
    withFilter: boolean;
    withSelection: boolean;
    withRemove: boolean;
    columns: TableColumn[];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    dataSourceSubscription: Subscription;
    filterDataSourceSubscription: Subscription;
    dataSource: MatTableDataSource<Element>;
    keyPath: string;
    displayPath: string;

    displayedColumns: string[] = [];
    selection: SelectionModel<any>;
    selectedStoreData: string;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.config) {
            this.storeData = this.config.storeData;
            this.filterStoreData = this.config.filterStoreData;
            this.selectedStoreData = this.config.selectedStoreData;

            this.maxHeight = this.config.maxHeight;
            this.withPagination = this.config.withPagination ?? false;
            this.withFilter = this.config.withFilter ?? false;
            this.withSelection = this.config.withSelection ?? false;
            this.withRemove = this.config.withRemove ?? false;

            if (this.config.columns) {
                this.columns = this.config.columns;
                this.displayedColumns = this.columns
                    .filter((c) => c.visible ?? false)
                    .map((c) => c.dataStoreKey);
            }
            if (this.withSelection) {
                this.displayedColumns = ['select', ...this.displayedColumns];
            }
            if (this.withRemove) {
                this.displayedColumns = [...this.displayedColumns, 'remove'];
            }
        }

        this.dataSource = new MatTableDataSource<any>([]);
        this.selection = new SelectionModel<any>(this.withSelection, []);
        this.selection.changed.subscribe((selection) => {
            if (this.selectedStoreData) {
                if (!selection.source.isMultipleSelection()) {
                    this.dynamicUiServices.SetValue(
                        this.selectedStoreData,
                        selection.source.selected[0]
                    );
                } else {
                    this.dynamicUiServices.SetValue(
                        this.selectedStoreData,
                        selection.source.selected
                    );
                }
            }
        });

        this.value$ = this.GetValueFromConfig();
        this.dataSourceSubscription = this.value$.subscribe((value) => {
            if (!this.filterStoreData) {
                this.dataSource.data = value;
            }
            else {
                var filter = this.dynamicUiServices.GetValue(this.filterStoreData);
                this.dataSource.data = this.filterData(value, filter);
            }
        });
        if (this.filterStoreData) {
            this.filterStoreData$ = this.dynamicUiServices.GetValueAsObservable(this.filterStoreData);
            this.filterDataSourceSubscription = this.filterStoreData$.subscribe(filter => {
                var value = this.dynamicUiServices.GetValue(this.storeData);
                this.dataSource.data = this.filterData(value, filter);
            })
        }

        if (this.selectedStoreData) {
            this.dynamicUiServices.GetValueAsObservable(this.selectedStoreData).subscribe(c => {
                if (this.withSelection) {
                    if (c && Array.isArray(c) && c.length > 0) {
                        // if (this.selection != c) {
                        //     this.selection = c;
                        // }
                    }
                    else {
                        // this.selectedValues = [];
                        // if (this.select?.options && this.select.options.length > 0) {
                        //     this.select.options.forEach((item: MatOption) => item.deselect());
                        // }
                    }
                }
                else {
                    if (c) {
                        // if (this.selectedValue != c) {
                        //     this.selectedValue = c;
                        // }
                    }
                    else {
                        // this.selectedValue = "";
                    }
                }
            });
        }
    }

    private filterData(value: any[], filter: any): any {
        var result = [...value];
        if (filter && result.length > 0) {
            if (filter) {
                Object.getOwnPropertyNames(filter).forEach(property => {
                    if (filter[property]) {
                        if (Array.isArray(filter[property])) {
                            if (filter[property].length > 0) {
                                result = result.filter(entry => {
                                    if (Object.getOwnPropertyNames(entry).includes(property)) {
                                        if (filter[property].includes(entry[property])) {
                                            return entry;
                                        }
                                    }
                                });
                            }
                        }
                        else {
                            result = result.filter(entry => {
                                if (Object.getOwnPropertyNames(entry).includes(property)) {
                                    if (entry[property] === filter[property]) {
                                        return entry;
                                    }
                                }
                            });
                        }
                    }
                })
            }
        }
        return result;
    }


    ngOnDestroy(): void {
        if (this.dataSourceSubscription) {
            this.dataSourceSubscription.unsubscribe();
        }
        if (this.filterDataSourceSubscription) {
            this.filterDataSourceSubscription.unsubscribe();
        }
        super.ngOnDestroy();
    }

    ngAfterViewInit(): void {
        if (this.withPagination) {
            this.dataSource.paginator = this.paginator;
        }
        this.dataSource.sort = this.sort;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    clearFilter(filterElement: HTMLInputElement) {
        filterElement.value = '';
        this.dataSource.filter = '';
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleRow(row: Element) {
        if (this.withSelection) {
            this.selection.toggle(row);
        } else {
            this.selection.select(row);
        }
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Element): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    removeRow(row: Element) {
        if (this.withRemove) {
            var values = [
                ...(this.dynamicUiServices.GetValue(this.storeData) as any[]),
            ];
            var index = values.indexOf(row);
            if (index > -1) {
                values.splice(index, 1);
            }
            this.dynamicUiServices.SetValue(this.storeData, values);
        }
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    removeAll() {
        if (this.withRemove) {
            this.dynamicUiServices.SetValue(this.storeData, []);
        }
    }
    // Datasource sortierung Beispiel
    // https://snyk.io/advisor/npm-package/@angular/material/functions/@angular%2Fmaterial.MatTableDataSource
}

